<template>
<div class="img">
    <img src="../assets/drawing2.png" alt="">
</div>
</template>

<script>
export default {
    name: "image-card",

}
</script>

<style scoped>
  .img{
    height: 100%;
  }

    img {
        height: 30rem;
    }

</style>