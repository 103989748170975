<template>
  <div class="home">
    <home-page />
  </div>
</template>

<script>
import HomePage from '@/components/HomePage.vue'


export default {
  name: 'HomeView',
  components: {
    HomePage
  
  }
}
</script>
