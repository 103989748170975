<template>
  <div class="blurb">
        <h1>Alex Stone</h1>
        <h2>software developer</h2>
        <p>solving complex problems with creativity and curiosity</p>

  </div>
</template>

<script>
export default {
    name: 'blurb-component',
}
</script>

<style scoped>
  .blurb{
    padding: 5rem 1rem;
    margin-block-end: auto;
  }
  h1 {
 
    font-family: 'Press Start 2P', cursive;
    font-size: 3rem;
    color: rgb(0, 34, 127)



  }

  h2 {
    color: rgb(4, 78, 78);
    font-family: 'Press Start 2P', cursive;
  }
</style>