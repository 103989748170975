import { render, staticRenderFns } from "./HomePage.vue?vue&type=template&id=0438c223&scoped=true&"
import script from "./HomePage.vue?vue&type=script&lang=js&"
export * from "./HomePage.vue?vue&type=script&lang=js&"
import style0 from "./HomePage.vue?vue&type=style&index=0&id=0438c223&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0438c223",
  null
  
)

export default component.exports